import { useStorage, useTimeoutFn } from '@vueuse/core'

export const usePlaybookTourStore = defineStore('playbook-tour', () => {
  const client = useClient()
  const totalTourSteps = 4
  const auth = useAuth()
  const isInitialized = ref(false)

  const currentTourStep = useStorage<number | null>('forgd:homepage:tour', null, undefined, {
    serializer: {
      read: (v: any) => v === null ? null : Number.parseFloat(v),
      write: (v: any) => Number(v || 0).toString(),
    },
  })

  // we need to disable the tour permanently for the user once they close it or finish
  const stopIsGuidedTourWatcher = watch(currentTourStep, (val) => {
    if (val && val > totalTourSteps) {
      client.users.updateUser({
        body: {
          isGuidedTourEnabled: false,
        },
      })
      stopIsGuidedTourWatcher()
    }
  })

  function initializeTour() {
    if (isInitialized.value) {
      return
    }

    isInitialized.value = true

    if (currentTourStep.value === null && auth.me?.isGuidedTourEnabled) {
      currentTourStep.value = 0
    }
  }

  onMounted(() => {
    useTimeoutFn(() => initializeTour(), 2000)
  })

  return {
    currentTourStep,
    totalTourSteps,
  }
})
